<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="ot-tip" #tipContainer>
  <div class="ot-tip__icon" *ngIf="!noIcon" (click)="openTip($event)" [class.hidden]="isOpen" #tip></div>
  <div class="ot-tip__popup" [class]="position" [hidden]="!isOpen"
       [style.width.px]="width"
       #popup>
    <div class="ot-tip__text">
      {{ text }}
      <ng-content></ng-content>
    </div>
    <div class="ot-tip__close" (click)="closeTip($event)"> {{buttonTitle}}</div>
  </div>
  <div class="ot-tip__background" *ngIf="isOpen" (click)="collapse($event)"></div>
</div>
