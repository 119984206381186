import { Pipe, PipeTransform } from '@angular/core';

const allDigitsExpectLast4Pattern = /\d(?=\d{3})/g;

@Pipe({ name: 'otBankNumber' })
export class BankNumberPipe implements PipeTransform {

  public transform(givenBankNumber: string): string {
    return givenBankNumber.replace(allDigitsExpectLast4Pattern, '*');
  }
}
