import {
  Component,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { GoToOktaLoginPage } from '../../../app.actions';

@Component({
  selector: 'ot-login-button',
  templateUrl: './login-button.html',
  styleUrls: ['login-button.scss']
})
export class LoginButtonComponent {
  @Input()
  public text: string = 'Log In';

  constructor(
    protected store: Store<any>
  ) {}

  public login() {
    this.store.dispatch(new GoToOktaLoginPage());
  }
}
