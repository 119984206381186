import {
  Directive,
  ElementRef,
  HostListener
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ot-request-button]'
})
export class RequestButtonDirective {
  private element = this.elementRef.nativeElement;

  @HostListener('click')
  private showValues() {
    this.element.disabled = true;

    setTimeout(() => {
      this.element.disabled = false;
    }, 500);
  }

  constructor(private elementRef: ElementRef) {
  }
}
