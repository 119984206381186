import { Observable, Observer } from 'rxjs';

export const exifStartUint16 = 0xFFE1;
export const rotationUint16 = 0x0112;

export interface ICanvasPosition {
  width: number;
  height: number;
  x: number;
  y: number;
}

export function getPosition(img: HTMLImageElement, degree): ICanvasPosition {
  const [width, height, x, y] = [img.width, img.height, 0, 0];
  const position = {
    0: {width, height, x, y},
    90: {width: height, height: width, x, y: height * (-1)},
    180: {width, height, x: width * (-1), y: height * (-1)},
    270: {width: height, height: width, x: width * (-1), y},
  };

  return position[degree] || position[0];
}

export function getRotation(loadImage: ArrayBuffer): number {
  const rotation = {
    1: 0,
    3: 180,
    6: 90,
    8: 270,
  };
  const scanner = new DataView(loadImage);
  let rotationCode = 1; // Non-rotated is the default

  if (loadImage.byteLength >= 2 && scanner.getUint16(0) === 0xFFD8) {
    rotationCode = getRotationCode(scanner);
  }

  return rotation[rotationCode];
}

export function getRotationCode(scanner): number {
  let code = 1;
  let isExif = false;
  let maxBytes = scanner.byteLength - 2;
  let uint16;

  for (let idx = 4; idx < maxBytes; idx += 2) {
    uint16 = scanner.getUint16(idx - 2);

    if (uint16 === exifStartUint16) { // Start of EXIF
      const exifLength = scanner.getUint16(idx);

      maxBytes = exifLength - idx;
      isExif = true;
    } else if (uint16 === rotationUint16 && isExif) { // Orientation tag
      // Read the value, its 6 bytes further out
      code = scanner.getUint16(idx + 6, false);
      maxBytes = 0; // Stop scanning
    }
  }

  return code;
}

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

export function rotate(loadImage: ArrayBuffer, type: string): Observable<string> {
  return Observable.create((observer: Observer<string>) => {
    const base64img = `data:${type};base64,${arrayBufferToBase64(loadImage)}`;
    const degree = getRotation(loadImage);
    const canvas = document.createElement('canvas');

    if (canvas.getContext && degree) {
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.src = base64img;
      img.onload = () => {
        const position = getPosition(img, degree);
        canvas.setAttribute('width', `${position.width}`);
        canvas.setAttribute('height', `${position.height}`);
        ctx.rotate(degree * Math.PI / 180);
        ctx.drawImage(img, position.x, position.y);
        observer.next(canvas.toDataURL(type));
        observer.complete();
      };
    } else {
      observer.next(base64img);
      observer.complete();
    }
  });
}
