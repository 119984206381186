import {
  Directive,
  Input,
  OnChanges,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
  Injector,
  SimpleChanges,
  Component,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[compile]',
})
export class CompileDirective implements OnChanges {
  @Input() compile!: string; // Non-null assertion for clarity
  @Input() compileContext?: any;

  private viewRef: any; // Use a more generic type

  constructor(
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["compile"]?.currentValue) {
      this.vcRef.clear();

      const component = this.createDynamicComponent();
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

      this.viewRef = this.vcRef.createComponent(componentFactory);
      this.updateProperties();
    } else {
      if (this.viewRef) {
        this.updateProperties();
        return;
      }
      this.vcRef.clear();
    }
  }

  private updateProperties() {
    if (this.viewRef && this.compileContext) {
      for (const prop in this.compileContext) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.compileContext.hasOwnProperty(prop)) {
          this.viewRef.instance[prop] = this.compileContext[prop];
        }
      }
    }
  }

  private createDynamicComponent(): Type<any> {
    @Component({
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: 'custom-dynamic-component',
      template: 'templateData',
      standalone: true, // Use standalone mode for Angular 17
    })
    class CustomDynamicComponent {}

    return CustomDynamicComponent;
  }
}
