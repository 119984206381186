<div class="ot-autosuggest" #wrapper [class]="{ disabled: disabled, expanded: expanded, error: error }">
  <input class="ot-autosuggest__input" autocomplete="off" #input
         [class]="{'error': error}"
         [id]="id"
         (change)="onInputChange($event)"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [value]="currentValue"
         [disabled]="disabled">
  <ul class="ot-autosuggest__list"
      #list
      [class]="{'scroll': isLongList()}"
      *ngIf="expanded">
    <li class="ot-autosuggest__list-item"
        *ngFor="let option of options; index as i; trackBy: trackOptions;"
        id="{{'item-' + i }}"
        [class]="{ focused: selectedIndex === i }"
        (mousedown)="onOptionSelect(option, $event)">{{option.label}}</li>
  </ul>
</div>

