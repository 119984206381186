import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'otKeepLastFourDigit' })
export class KeepLastFourDigitPipe implements PipeTransform {

  public transform(value: string): string {
    const lastFour = value.slice(-4);
    const firstPart = value.slice(0, -4);

    return firstPart.replace(/\d/g, '*').concat(lastFour);
  }
}
