import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  AvatarComponent,
  CaptchaComponent,
  SelectComponent,
  AutosuggestComponent,
  SignatureComponent,
  LoginButtonComponent,
  ShowMoreComponent,
  DialogComponent,
  PlayerComponent,
  TipComponent,
  ManagerCardComponent,
  ScrollProgressComponent,
  CarouselItemDirective,
  CarouselItemElementDirective,
  CarouselComponent
} from './components';

import {
  CompileDirective,
  TooltipDirective,
  SensitiveInputDirective,
  RequestButtonDirective
} from './directives';

import {
  PhonePipe,
  PlayerTimerPipe,
  BankNumberPipe,
  KeepLastFourDigitPipe,
} from './pipes';
import {
  RecaptchaLoaderService,
  RecaptchaModule
} from 'ng-recaptcha';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RecaptchaModule
  ],
  declarations: [
    AvatarComponent,
    CaptchaComponent,
    SelectComponent,
    AutosuggestComponent,
    SignatureComponent,
    LoginButtonComponent,
    ShowMoreComponent,
    ManagerCardComponent,
    ScrollProgressComponent,
    CompileDirective,
    TooltipDirective,
    SensitiveInputDirective,
    RequestButtonDirective,
    DialogComponent,
    PlayerComponent,
    TipComponent,
    PhonePipe,
    PlayerTimerPipe,
    BankNumberPipe,
    KeepLastFourDigitPipe,
    CarouselItemDirective,
    CarouselItemElementDirective,
    CarouselComponent
  ],
  exports:      [
    AvatarComponent,
    CaptchaComponent,
    SelectComponent,
    AutosuggestComponent,
    SignatureComponent,
    LoginButtonComponent,
    ShowMoreComponent,
    ManagerCardComponent,
    ScrollProgressComponent,
    CarouselItemDirective,
    CarouselItemElementDirective,
    CarouselComponent,
    CompileDirective,
    TooltipDirective,
    SensitiveInputDirective,
    RequestButtonDirective,
    DialogComponent,
    PlayerComponent,
    TipComponent,
    PhonePipe,
    PlayerTimerPipe,
    BankNumberPipe,
    KeepLastFourDigitPipe
  ],
  providers: [
    PlayerTimerPipe,
    PhonePipe,
    BankNumberPipe,
    KeepLastFourDigitPipe,
    RecaptchaLoaderService
  ]
})
export class SharedModule {}
