import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ot-tooltip]'
})

export class TooltipDirective implements OnDestroy {

  constructor(private elementRef: ElementRef) {
  }

  private tooltip: any;
  private elemPosition: any;
  private tooltipOffset: number = 8;
  private hideTimeoutId: number;
  private showTimeoutId: number;

  @Input() tooltipText = '';
  @Input() placement = 'top';
  @Input() private delay = 0;
  @Input() private showDelay = 0;
  @Input() private hideDelay = 300;
  @Input() private zIndex = false;

  @HostListener('focusin')
  @HostListener('mouseenter')
  @HostListener('mousemove')
  private onMouseEnter() {
    this.getElemPosition();

    if (!this.tooltip) {
      this.create();
      this.setPosition();
      this.show();
    }
  }

  @HostListener('window:resize')
  private onWindowResize() {
    if (this.tooltip) {
      this.getElemPosition();
      this.setPosition();
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('mousedown')
  private onMouseLeave() {
    this.hide();
  }

  private getElemPosition() {
    this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  private create() {
    this.showDelay = this.delay || this.showDelay;
    this.tooltip = document.createElement('span');
    this.tooltip.className += 'ng-tooltip ng-tooltip-' + this.placement;
    this.tooltip.textContent = this.tooltipText;
    if (this.zIndex) {
      this.tooltip.style.zIndex = this.zIndex;
    }

    document.body.appendChild(this.tooltip);
  }

  private show() {
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    this.showDelay = this.delay || this.showDelay;
    this.showTimeoutId = window.setTimeout(() => {
      if (this.tooltip) {
        this.tooltip.className += ' ng-tooltip-show';
      }
    }, this.showDelay);
  }

  private hide() {
    clearTimeout(this.showTimeoutId);

    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (this.tooltip) {
      this.tooltip.classList.remove('ng-tooltip-show');
      this.hideTimeoutId = window.setTimeout(() => {
        this.tooltip.parentNode.removeChild(this.tooltip);
        this.tooltip = null;
      }, this.hideDelay);
    }
  }

  private setPosition() {
    const elemHeight = this.elementRef.nativeElement.offsetHeight;
    const elemWidth = this.elementRef.nativeElement.offsetWidth;
    const tooltipHeight = this.tooltip.clientHeight;
    const tooltipWidth = this.tooltip.offsetWidth;
    const scrollY = window.pageYOffset;
    const toolTipStyles = {
      top: () => {
        this.tooltip.style.top =
          (this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) - 10 + 'px';
        this.tooltip.style.left =
          (this.elemPosition.left + elemWidth / 2) - tooltipWidth / 2 + 'px';
      },
      bottom: () => {
        this.tooltip.style.top =
          (this.elemPosition.top + scrollY) + elemHeight + this.tooltipOffset + 'px';
        this.tooltip.style.left =
          (this.elemPosition.left + elemWidth / 2) - tooltipWidth / 2 + 'px';
      },
      left: () => {
        this.tooltip.style.left =
          this.elemPosition.left - tooltipWidth - this.tooltipOffset + 'px';
        this.tooltip.style.top =
          (this.elemPosition.top + scrollY) + elemHeight / 2 - this.tooltip.clientHeight / 2 + 'px';
      },
      right: () => {
        this.tooltip.style.left =
          this.elemPosition.left + elemWidth + this.tooltipOffset + 'px';
        this.tooltip.style.top =
          (this.elemPosition.top + scrollY) + elemHeight / 2 - this.tooltip.clientHeight / 2 + 'px';
      }
    };

    toolTipStyles[this.placement]();
  }

  public ngOnDestroy() {
    this.hide();
  }
}
