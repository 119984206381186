/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { getElementCenterCoordinates } from '../../../util';

@Component({
  selector: 'ot-tip',
  templateUrl: './tip.html',
  styleUrls: ['tip.scss']
})
export class TipComponent implements OnChanges {
  @Input() public text: string = '';
  @Input() public isOpen: boolean = false;
  @Input() public popupCoordinates: ICoordinates;
  @Input() public buttonTitle: string = 'OK, Got it';
  @Input() public position: TipPositionEnum = TipPositionEnum.BOTTOM;
  @Input() public noIcon: boolean = false;
  @Input() public width: string = '342px';
  @Output('onOpen') private onOpen: EventEmitter<Event> = new EventEmitter<Event>();
  @Output('onClose') private onClose: EventEmitter<Event> = new EventEmitter<Event>();

  @ViewChild('tip') private tip: ElementRef;
  @ViewChild('tipContainer') private tipContainer: ElementRef;
  @ViewChild('popup') private popup: ElementRef;

  public ngOnChanges() {
    if (this.isOpen) {
      setTimeout(this.movePopupToPosition.bind(this), 0);
    }
  }

  public openTip(event) {
    this.onOpen.emit(event);
  }

  public closeTip(event) {
    event.stopPropagation();

    this.onClose.emit(event);
  }

  public collapse(event) {
    event.stopPropagation();

    if (this.noIcon) {
      this.onClose.emit(event);
    }
  }

  private movePopupToPosition() {
    const {top, left} = this.getNextPositionCoordinates();
    const popup = this.popup.nativeElement;
    const startingCoordinates = this.getComponentCoordinates();
    const {top: adjTop, left: adjLeft} = this.getAdjustments();

    const resultLeft = left - startingCoordinates.left + adjLeft;
    const resultTop = top - startingCoordinates.top + adjTop;

    popup.style.top = `${resultTop}px`;
    popup.style.left = `${resultLeft}px`;
  }

  private getNextPositionCoordinates(): ICoordinates {
    return !this.popupCoordinates
      ? getElementCenterCoordinates((this.noIcon ? this.tipContainer : this.tip))
      : this.popupCoordinates;
  }

  private getComponentCoordinates(): ICoordinates {
    const tipRect = (this.noIcon ? this.tipContainer : this.tip).nativeElement.getBoundingClientRect();

    return {
      top: tipRect.top + window.pageYOffset,
      left: tipRect.left + window.pageXOffset
    };
  }

  private getAdjustments(): ICoordinates {
    const {
      offsetHeight: popupHeight,
      offsetWidth: popupWidth
    } = this.popup.nativeElement;

    const adjustments = {
      [TipPositionEnum.TOP]: {
        top: -popupHeight - 6,
        left: -(popupWidth / 2) + 6
      },
      [TipPositionEnum.BOTTOM]: {
        top: 6,
        left: -(popupWidth / 2) + 6
      },
      [TipPositionEnum.LEFT]: {
        top: -30,
        left: -popupWidth - 6
      },
      [TipPositionEnum.RIGHT]: {
        top: -30,
        left: 6
      }
    };

    return adjustments[this.position];
  }
}

export interface ICoordinates {
  top: number;
  left: number;
}

export enum TipPositionEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right'
}
