import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
  AfterViewInit
} from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'ot-signature',
  templateUrl: './signature.html',
  styleUrls: ['signature.scss']
})
export class SignatureComponent implements AfterViewInit  {
  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasElement.nativeElement, {
      onEnd: this.onEnd.bind(this),
    });
    this.adjustCanvasSize();
  }

  @Input() public error: boolean = false;
  @Input() public id: string = '';
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix
  @Output('onChange') private onChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('canvas') public canvasElement: ElementRef;
  @ViewChild('wrapper') public wrapperElement: ElementRef;
  @ViewChild('scaler') public scalerElement: ElementRef;

  windowWidth = window.innerWidth;

  @HostListener('window:resize') private onWindowResize() {
    if (window.innerWidth !== this.windowWidth) {
      this.windowWidth = window.innerWidth;

      this.adjustCanvasSize();
    }
  }

  @HostListener('window:orientationchange') private onWindowOrientationChange() {
    setTimeout(() => {
      this.adjustCanvasSize();
      // Workaround Iphone 7 deffect OT-1732.
      setTimeout(window.scrollTo, 250);
    }, 50);
  }

  public signaturePad;

  public onEnd(event) {
    this.onChange.emit(Object.assign(event.target, {
      value: this.signaturePad.toDataURL()
    }));
  }

  adjustCanvasSize() {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this.canvasElement.nativeElement;
    const scalerWidth = this.scalerElement.nativeElement.offsetWidth;
    const scalerHeight = scalerWidth / 3;

    this.scalerElement.nativeElement.style.height = `${scalerHeight}px`;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.clear();
  }

  public clear() {
    if (!this.signaturePad.isEmpty()) {
      this.signaturePad.clear();
      this.onChange.emit('');
    }
  }
}
