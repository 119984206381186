import {
  Directive,
  ElementRef,
  HostListener,
  OnInit
} from '@angular/core';

const inputTypes = {
  text: 'text',
  pass: 'password'
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ot-sensitive-input]'
})
export class SensitiveInputDirective implements OnInit {
  @HostListener('focus')
  private showValues() {
    this.elementRef.nativeElement.type = inputTypes.text;
  }

  @HostListener('blur')
  private hideValues() {
    this.elementRef.nativeElement.type = inputTypes.pass;
  }

  constructor(private elementRef: ElementRef) {
  }

  public ngOnInit() {
    this.elementRef.nativeElement.type = inputTypes.pass;
  }
}
