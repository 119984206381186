<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="player" #playerElement
     [class]="{ 'no-pointer': isNoPointer() }"
     (mousemove)="showControls()"
     (mouseleave)="hideControls()" xmlns="http://www.w3.org/1999/html">
  <video class="player__element" #videoElement
         (ended)="onVideoEnd()"
         (loadeddata)="onVideoLoad()"
         (durationchange)="onDurationChange()"
         (timeupdate)="onTimeUpdate()"
         (contextmenu)="false"
         playsinline
         [class]="{ wide: isFullscreenEnabled  }">
    <source [src]="src" type="video/mp4">
  </video>
  <div class="gray-overlay" *ngIf="isPaused"></div>
  <div class="large-play" *ngIf="isPaused" (click)="togglePause($event)"></div>
  <div class="player__controls" [class]="{
    wide: isFullscreenEnabled,
    'hide-controls': isControlsHidden && !isPaused && !isQualityListVisible && !isControlsLocked
  }">
    <div class="progress" #progressElement (click)="progressChange($event)" [class]="{ progressEditable: progressEditable }">
      <div class="progress__current"
           [class]="{ animated: isProgressAnimated() }"
           [style.width.px]="progress"></div>
    </div>
    <div class="buttons">
      <div class="play"
           [class]="{ paused: isPaused }"
           (mouseenter)="lockControls()"
           (mouseleave)="unLockControls()"
           (click)="togglePause($event)">
        <div class="play__icon"></div>
      </div>
      <div class="timer">
        <span class="current">{{currentTime}}</span>  <span class="time-delimiter">/</span>  <span class="duration">{{duration}}</span>
      </div>
      <div class="quality" *ngIf="qualityList.length"
           (mouseenter)="lockControls()"
           (mouseleave)="unLockControls()">
        <div class="quality__current" (click)="toggleQualityList($event)">{{currentQuality}}</div>
        <div class="quality__list" *ngIf="isQualityListVisible">
          <div class="quality__list-item"
               *ngFor="let qualityOption of qualityList;"
               (click)="selectQuality(qualityOption, $event)">{{qualityOption}}</div>
        </div>
      </div>
      <div class="fullscreen"
           (mouseenter)="lockControls()"
           (mouseleave)="unLockControls()"
           (click)="toggleFullscreen($event)">
        <div class="fullscreen__icon" [class]="{ 'disable-fullscreen': isFullscreenEnabled }"></div>
      </div>
    </div>
  </div>
</div>

