<section class="carousel-wrapper">
  <ul class="carousel-inner" #carousel [ngStyle]="{
  left: leftPosition
  }">
    <li *ngFor="let item of items;" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
  <ul class="carousel-dots" #carousel>
    <li *ngFor="let item of items; let i = index"
        class="carousel-dot"
        [class]="{
          active: i === currentSlide
        }"></li>
  </ul>
</section>
