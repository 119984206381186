<div class="manager-card">
  <div class="manager-card__img" [ngStyle]="{'background-image':  'url(' + photoUrl + '),' + 'url(/assets/img/default-avatar.png)'}">
  </div>

  <div class="manager-card__info">
    <div class="manager-card__info__name">{{ firstName }} {{ lastName }}</div>

    <ul class="manager-card__contacts">
      <li class="manager-card__contacts__phone" *ngIf="phone">
        <a href="tel:{{ phone | otPhone}}">{{ phone | otPhone}}</a>
      </li>
      <li class="manager-card__contacts__email icon-mail" *ngIf="email">{{ email }}</li>
    </ul>

  </div>
</div>
