/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { PlayerTimerPipe } from '../../pipes/player-timer';
import {
  isMobileDevice,
  requestFullScreen,
  exitFullScreen,
  isFullscreenEnabled,
} from '../../../util';

@Component({
  selector: 'ot-player',
  templateUrl: './player.html',
  styleUrls: ['player.scss']
})
export class PlayerComponent implements OnChanges, AfterViewInit {
  @Input() public progressEditable: boolean = false;
  @Input() public src: string = '';
  @Input() public qualityList: string[] = [];
  @Input() public currentQuality: string = '';
  @Input() public startFrom: number = 0;
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('onQualityChange')
    private onQualityChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() private onFinish: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() private onProgress: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('playerElement') public playerElement: ElementRef;
  @ViewChild('videoElement') public videoElement: ElementRef;
  @ViewChild('progressElement') public progressElement: ElementRef;
  @HostListener('window:resize') private onWindowResize() {
    if (!isMobileDevice && this.isFullscreenEnabled !== isFullscreenEnabled()) {
      this.isFullscreenEnabled = isFullscreenEnabled();
    }
  }
  constructor(
    protected playerTimerPipe: PlayerTimerPipe
  ) {}

  
  ngAfterViewInit(): void {
       // workaround to play video in iOS10
       setTimeout(() => {
        this.videoElement.nativeElement.load();
      }, 0);
  }


  public isPaused: boolean = true;
  public progress: number = 0;
  public duration: string;
  public currentTime: string;
  public isQualityListVisible: boolean = false;
  public isFullscreenEnabled: boolean = false;
  public isControlsHidden: boolean = false;
  public isControlsLocked: boolean = false;
  private controlsHideTimer;

  public ngOnChanges(changes) {
    const { src } = changes;

    if (src && src.currentValue !== src.previousValue && this.videoElement) {
      this.videoElement.nativeElement.load();
    }
  }

  public onVideoLoad() {
    if (this.startFrom) {
      this.videoElement.nativeElement.currentTime = this.startFrom;

      if (!this.isPaused) {
        this.play();
      }
    }
  }

  public togglePause(e) {
    e.stopPropagation();

    this.isPaused ? this.play() : this.pause();
  }

  public onVideoEnd() {
    this.isPaused = true;
    this.onFinish.emit();
  }

  public onDurationChange() {
    const { duration = 0, currentTime = 0 } = this.videoElement.nativeElement;

    this.duration = this.playerTimerPipe.transform(duration);
    this.currentTime = this.playerTimerPipe.transform(currentTime);
  }

  public onTimeUpdate() {
    const { clientWidth: progressBarWidth = 0 } = this.progressElement.nativeElement;
    const { duration = 0, currentTime = 0 } = this.videoElement.nativeElement;

    this.progress = +(currentTime * progressBarWidth / duration).toFixed();
    this.currentTime = this.playerTimerPipe.transform(currentTime);
    this.onProgress.emit(currentTime);
  }

  public isProgressAnimated() {
    return this.progress > 0 && !this.isPaused;
  }

  public toggleQualityList(e) {
    e.stopPropagation();

    this.isQualityListVisible = !this.isQualityListVisible;
  }

  public selectQuality(selectedQuality, e) {
    e.stopPropagation();

    this.onQualityChange.emit(selectedQuality);
    this.currentQuality = selectedQuality;
    this.isQualityListVisible = false;
  }

  public toggleFullscreen(e) {
    e.stopPropagation();

    this.isFullscreenEnabled
      ? this.disableFullScreen()
      : this.enableFullScreen();
  }

  public showControls() {
    this.isControlsHidden = false;
    clearTimeout(this.controlsHideTimer);
    this.controlsHideTimer = setTimeout( () =>  this.hideControls(), 3000);
  }

  public hideControls() {
    this.isControlsHidden = true;
    clearTimeout(this.controlsHideTimer);
  }

  public lockControls() {
    if (!isMobileDevice) {
      this.isControlsLocked = true;
    }
  }

  public unLockControls() {
    if (!isMobileDevice) {
      this.isControlsLocked = false;
    }
  }

  public isNoPointer() {
    return this.isControlsHidden
      && !this.isPaused
      && this.isFullscreenEnabled
      && !this.isControlsLocked;
  }
  private enableFullScreen() {
    this.isFullscreenEnabled = true;
    requestFullScreen(this.playerElement.nativeElement);
  }

  private disableFullScreen() {
    this.isFullscreenEnabled = false;
    exitFullScreen();
  }

  public play() {
    this.videoElement.nativeElement.play();
    this.isPaused = false;
  }

  public pause() {
    this.videoElement.nativeElement.pause();
    this.isPaused = true;
  }

  public progressChange(e) {
    e.stopPropagation();

    if (this.progressEditable) {
      const totalWidth = this.progressElement.nativeElement.offsetWidth;
      const percentage = ( e.offsetX / totalWidth );
      const vidTime = this.videoElement.nativeElement.duration * percentage;

      this.videoElement.nativeElement.currentTime = vidTime;
      this.onTimeUpdate();
    }
  }
}
