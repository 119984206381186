<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="show-more" #content>
  <div class="show-more__head">
    {{ initialText }}
    <label class="show-more__label" (click)="toggle()" [class]="{ 'expanded': expanded, right: rightAlign }">{{expanded ? 'Hide' : label}}</label>
  </div>
  <div class="show-more__content" *ngIf="expanded">
    <ng-content></ng-content>
  </div>
  <div class="show-more__footer">
    <label class="show-more__label" (click)="collapse()" *ngIf="expanded && bottomHide" [class]="{ 'expanded': expanded, right: rightAlign }">Hide</label>
  </div>
</div>
