import {
  Directive,
  TemplateRef
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[carouselItem]'
})
export class CarouselItemDirective {
  constructor( public tpl: TemplateRef<any> ) {}
}
