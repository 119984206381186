import {
  Component,
  ElementRef,
  HostListener,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'ot-scroll-progress',
  templateUrl: './scroll-progress.html',
  styleUrls: ['scroll-progress.scss']
})
export class ScrollProgressComponent {
  @ViewChild('progressBar') public progressBar: ElementRef;
  public progressWidth: string = '';

  @HostListener('window:scroll', ['$event']) public progress() {
    this.setScrollWidth();
  }

  @HostListener('window:resize', ['$event']) public resize() {
    this.setScrollWidth();
  }

  private setScrollWidth() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollBottom =
      (document.documentElement.scrollHeight ||
        document.body.scrollHeight) - document.documentElement.clientHeight;

    this.progressWidth = scrollTop / scrollBottom * 100 + '%';
  }
}
