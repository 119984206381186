/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowToastr, HideToastr } from '../../../app.actions';
import { ToastrTypesEnum } from '../../../constants';
import { rotate } from './avatar.helper';

export const ImgSizeLimit = 1e7;
export const ImgSizeErrorMessage = 'Your image is larger than 10 MB. Please choose a smaller one.';

@Component({
  selector: 'ot-avatar',
  templateUrl: './avatar.html',
  styleUrls: ['avatar.scss']
})
export class AvatarComponent {
  @Input() public imgSource: string = '';
  @Input() public loadfile: boolean = true;
  @Input() public width: number = 100;
  @Input() public height: number = 100;
  @Input() public canEdit: boolean = true;
  @Input() public canRemove: boolean = true;
  @Input() public customContent: boolean = false;

  @Output() public onDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onEdit: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected store: Store<any>) {}

  public onDeleteClick() {
    this.store.dispatch(new HideToastr());
    this.onDelete.emit();
  }

  public onEditClick() {
    this.store.dispatch(new HideToastr());
  }

  public fileChangeListener(e) {
    const [file]: File[] =  e.target.files;
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (loadEvent: any) => {
      if (loadEvent.total > ImgSizeLimit) {
        this.showToastr(ToastrTypesEnum.error, ImgSizeErrorMessage);
      } else {
        this.editImage(loadEvent.target.result, file.type);
      }
      e.target.value = '';
    };
    myReader.readAsArrayBuffer(file);
  }

  public editImage(loadImage: ArrayBuffer, type: string) {
    rotate(loadImage, type).subscribe((newImg) => {
        this.onEdit.emit(newImg);
      });
  }

  public showToastr(type: ToastrTypesEnum, message: string) {
    this.store.dispatch(new ShowToastr({type, message}));
  }
}
