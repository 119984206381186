import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CarouselItemDirective } from './carousel.item.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '.carousel-item'
})
export class CarouselItemElementDirective {
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'carousel',
  exportAs: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective) public items: QueryList<CarouselItemDirective>;
  @ViewChildren(CarouselItemElementDirective, { read: ElementRef }) public itemsElements: QueryList<ElementRef>;
  @ViewChild('carousel') private carousel: ElementRef;
  @Input() public showControls = true;
  @Input() public currentSlide = 0;

  private itemWidth: number;
  public leftPosition = '-0px';

  @HostListener('window:resize', ['$event']) public onResize() {
    this.initialCalculation();
  }

  public next() {
    if (this.currentSlide + 1 === this.items.length) {
      return;
    }
    this.currentSlide = (this.currentSlide + 1) % this.items.length;

    this.changePosition();
  }

  public prev() {
    if (this.currentSlide === 0) {
      return;
    }

    this.currentSlide = ((this.currentSlide - 1) + this.items.length) % this.items.length;

    this.changePosition();
  }

  private changePosition() {
    const offset = this.currentSlide * this.itemWidth;

    this.leftPosition = `-${offset}px`;
  }

  private initialCalculation() {
    this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;

    this.changePosition();
  }

  public ngAfterViewInit() {
    this.initialCalculation();
  }
}
