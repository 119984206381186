import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';

const KEY_CODES = {
  esc: 27,
  tab: 9,
  tabKey: 'Tab'
};
const focusableElementsSelectors: string[] = [
  'a[href]',
  'button',
  'textarea',
  'input[type="text"]',
  'input[type="radio"]',
  'input[type="checkbox"]',
  'select'
];

export interface ISizeSettings {
  desktopWidth?: string;
  desktopMaxWidth?: string;
  tabletWidth?: string;
  tabletMaxWidth?: string;
  mobileWidth?: string;
  mobileMaxWidth?: string;
}

const initialSizes = {
  desktopWidth: '90%',
  desktopMaxWidth: '546px',
  tabletWidth: '100%',
  tabletMaxWidth: '546px',
  mobileWidth: '100%',
  mobileMaxWidth: '100%',
};

@Component({
  selector: 'ot-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['dialog.scss']
})
export class DialogComponent implements OnChanges, OnDestroy {
  private _sizes: ISizeSettings = {};

  public get sizes() {
    return window.innerWidth >= 1024
      ? {
        width: this._sizes.desktopWidth,
        maxWidth: this._sizes.desktopMaxWidth
      }
      : window.innerWidth >= 425
        ? {
          width: this._sizes.tabletWidth,
          maxWidth: this._sizes.tabletMaxWidth
        }
        : {
          width: this._sizes.mobileWidth,
          maxWidth: this._sizes.mobileMaxWidth
        };
  }

  @Input() public set sizeSettings(settings: ISizeSettings) {
    this._sizes = {
      ...initialSizes,
      ...settings
    };
  }

  @Input() public title: string = '';
  @Input() public tabletWidth: number = 546;
  @Input() public mobileFlex: boolean = false;
  @Input() public desktopWidth: number = 546;
  @Input() public isError: boolean = false;
  @Input() public hideCloseButton: boolean = false;
  @Input() public opened: boolean = true;
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix
  @Output('onClose') public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dialog') private dialog: ElementRef;

  @HostListener('document:click', ['$event']) private onDocumentClick($event) {
    if (this.opened && !this.dialog.nativeElement.contains($event.target)) {
      this.close();
    }
  }

  @HostListener('document:keydown', ['$event']) public onDocumentKeyPress($event) {
    const keysEvents = {
      [KEY_CODES.esc]: this.close.bind(this),
      /* tslint:disable */
      other: () => {},
      /* tslint:enable */
    };

    if (this.opened) {
      keysEvents[keysEvents[$event.keyCode] ? $event.keyCode : 'other']($event);
    }
  }

  private trapFocusListener(firstEl, lastEl) {
    return (e) => {
      const isTabPressed = e.key === KEY_CODES.tabKey || e.keyCode === KEY_CODES.tab;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey && document.activeElement === firstEl) /* shift + tab */ {
        lastEl.focus();
        e.preventDefault();
      }

      if (!e.shiftKey && document.activeElement === lastEl)/* tab */ {
        firstEl.focus();
        e.preventDefault();
      }
    };
  }

  private trapFocus() {
    if (!this.dialog) {
      return;
    }

    const dialog = this.dialog.nativeElement;
    const focusableEls = dialog.querySelectorAll(focusableElementsSelectors.join(', '));
    const [firstFocusableEl] = focusableEls;
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    firstFocusableEl.focus();

    dialog.addEventListener('keydown', this.trapFocusListener(firstFocusableEl, lastFocusableEl));
  }

  public close() {
    this.onClose.emit();
  }

  public ngOnChanges(changes) {
    if (changes.opened && changes.opened.currentValue) {
      document.documentElement.classList.add('with-overlay');
      setTimeout(() => this.trapFocus(), 99);
    }

    if (changes.opened && !changes.opened.currentValue) {
      document.documentElement.classList.remove('with-overlay');
    }
  }

  public ngOnDestroy() {
    document.documentElement.classList.remove('with-overlay');
  }
}
