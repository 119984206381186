import { Component, Input } from '@angular/core';

@Component({
  selector: 'ot-manager-card',
  templateUrl: './manager-card.html',
  styleUrls: ['manager-card.scss']
})
export class ManagerCardComponent {
  @Input() public photoUrl: string = '';
  @Input() public firstName: string = '';
  @Input() public lastName: string = '';
  @Input() public phone: string = '';
  @Input() public email: string = '';
}
