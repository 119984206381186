<div class="ot-signature" #wrapper>
  <label class="ot-signature__label" [for]="id">
    Please leave your signature here:
  </label>
  <div>
    
    <!--eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus-->
    <div class="ot-signature__clear" (click)="adjustCanvasSize()"></div>
    <div class="ot-signature__scaler" #scaler>
      <canvas [id]="id" class="ot-signature__pad" #canvas
              [class]="{ error: error }"></canvas>
    </div>
  </div>
</div>

