/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';

export interface Option {
  value: string | number;
  label: string;
}

interface ISettings {
  maxListLength: number;
  fieldId: string;
}

const KEY_CODES = {
  arrowUp: 38,
  arrowDown: 40,
  enter: 13,
  esc: 27
};

@Component({
  selector: 'ot-autosuggest',
  templateUrl: './autosuggest.html',
  styleUrls: ['autosuggest.scss']
})
export class AutosuggestComponent implements OnInit, OnChanges {
  @Input() public options: Option[] = [];
  @Input() public error: boolean = false;
  @Input() public initialValue: string = '';
  @Input() public disabled: boolean = false;
  @Input() public searchable: boolean = true;
  @Input() public settings: object = AutosuggestComponent.DEFAULT_SETTINGS;
  @Output('onSelect') private onSelect: EventEmitter<Option> = new EventEmitter<Option>();
  @Output('onChange') private onChange: EventEmitter<string> = new EventEmitter<string>();
  public selectedIndex = -1;

  @ViewChild('wrapper') private wrapper: ElementRef;
  @ViewChild('input') private input: ElementRef;
  @ViewChild('list') public list: ElementRef;

  @HostListener('document:click', ['$event']) private onDocumentClick($event) {
    if (!this.wrapper.nativeElement.contains($event.target)) {
      this.expanded = false;
    }
  }

  @HostListener('document:keydown', ['$event']) public onDocumentKeyPress($event) {
    const keysEvents = {
      [KEY_CODES.arrowDown]: this.onArrowDown.bind(this),
      [KEY_CODES.arrowUp]: this.onArrowUp.bind(this),
      [KEY_CODES.enter]: this.onEnterKey.bind(this),
      [KEY_CODES.esc]: this.onBlur.bind(this),
      /* tslint:disable */
      other: () => {},
      /* tslint:enable */
    };

    if (this.expanded) {
      keysEvents[keysEvents[$event.keyCode] ? $event.keyCode : 'other']($event);
    }
  }

  private onArrowDown($event): void {
    $event.preventDefault();

    if (this.selectedIndex < this.options.length - 1) {
      this.selectedIndex++;
      this.scrollInsideBottom();
    }
  }

  private onArrowUp($event): void {
    $event.preventDefault();

    if (this.selectedIndex >= 0) {
      this.selectedIndex--;
      this.scrollInsideTop();
    }
  }

  private onEnterKey($event): void {
    $event.preventDefault();

    if (this.selectedIndex >= 0) {
      this.onOptionSelect(this.options[this.selectedIndex], $event);
    }
  }

  private scrollInsideBottom(): void {
    const el = document.getElementById(`item-${this.selectedIndex}`);
    const topPos = el.offsetTop;
    const height = el.offsetHeight;

    if (topPos + height > this.list.nativeElement.scrollTop + 320) {
      this.list.nativeElement.scrollTop = topPos + height - 326;
    }

    if (topPos + height < this.list.nativeElement.scrollTop) {
      this.list.nativeElement.scrollTop = topPos;
    }
  }

  private scrollInsideTop() {
    if (this.selectedIndex >= 0) {
      const el = document.getElementById(`item-${this.selectedIndex}`);
      const topPos = el.offsetTop;
      const height = el.offsetHeight;

      if (topPos < this.list.nativeElement.scrollTop) {
        this.list.nativeElement.scrollTop = topPos ;
      }

      if (topPos > this.list.nativeElement.scrollTop + 320) {
        this.list.nativeElement.scrollTop = topPos + height - 326;
      }
    }
  }

  public trackOptions(index, item: Option) {
    return item.value;
  }

  private static readonly DEFAULT_SETTINGS: ISettings = {
    maxListLength : 9,
    fieldId: ''
  };

  public id: string = '';
  public expanded: boolean = false;
  public currentValue: string = '';

  private combinedSettings: ISettings;

  public ngOnInit() {
    this.combinedSettings = Object.assign({}, AutosuggestComponent.DEFAULT_SETTINGS, this.settings);
    this.currentValue = this.initialValue;

    const { fieldId } = this.combinedSettings;

    this.id = fieldId;
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.currentValue = changes['initialValue'] ?
      changes['initialValue'].currentValue :
      this.currentValue;
  }

  public onOptionSelect(option: Option, event) {
    event.stopPropagation();
    this.expanded = false;
    this.selectedIndex = -1;

    return this.onSelect.emit(option);
  }

  public onInputChange(event) {
    this.onChange.emit(event.target.value);
    this.selectedIndex = -1;
  }

  public onFocus() {
    this.expanded = true;
  }

  public onBlur() {
    this.selectedIndex = -1;
    this.expanded = false;
  }

  public onToggle() {
    this.expanded = this.disabled ? false : !this.expanded;
  }

  public isLongList() {
    const { maxListLength } = this.combinedSettings;

    return this.options.length > maxListLength;
  }

  public clearInput(event) {
    event.target.value = '';
  }
}
