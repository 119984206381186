<div class="ot-select" #wrapper [class]="{ disabled: disabled, expanded: expanded, searchable: searchable, error: error }">
  <input class="ot-select__input" #input
         [class]="{'error': error}"
         [id]="id"
         (change)="clearInput($event)"
         (input)="onInputChange($event)"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [placeholder]="currentValue || 'Select'"
         [disabled]="disabled"
          *ngIf="searchable">
  <input class="ot-select__input--hidden"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [disabled]="disabled"
         *ngIf="!searchable">
  <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
  <label [for]="id" class="ot-select__value" (click)="onToggle()" [class]="{ 'disabled': disabled }">
    <span *ngIf="currentValue">{{ currentValue }}</span>
    <span *ngIf="!currentValue" class="ot-select__value--default">Select</span>
  </label>
  <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus , @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/label-has-associated-control -->
  <label class="ot-select__toggle" (click)="onToggle()"></label>
  <ul class="ot-select__list"
      #list
      [class]="{'scroll': isLongList()}"
      *ngIf="expanded">
    <li class="ot-select__list-item"
        *ngFor="let option of options; index as i; trackBy: trackOptions;"
        id="{{'item-' + i }}"
        [class]="{ focused: selectedIndex === i }"
        (mousedown)="onOptionSelect(option, $event)">{{option.label}}</li>
    <li class="ot-select__list-item no-results" *ngIf="!options.length">No results found</li>
  </ul>
</div>

