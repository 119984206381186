<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/elements-content -->
<div class="avatar-holder"
     [ngStyle]="{'width':  width + 'px', 'height':  height + 'px'}"
      *ngIf="!customContent">
  <div class="avatar"
       [ngStyle]="{'background-image':  'url(' + imgSource + '),' + 'url(/assets/img/default-avatar.png)'}">
  </div>
  <button class="avatar__btn avatar__btn--remove"
          (click)="onDeleteClick()"
          *ngIf="canRemove"></button>
  <div class="avatar__btn avatar__btn--edit"
       (click)="onEditClick()"
       *ngIf="canEdit">
    <input type="file"
           *ngIf="loadfile"
           accept="image/x-png, image/gif, image/jpeg"
           class="avatar__edit-input"
           (change)="fileChangeListener($event)">
  </div>
</div>
<div class="avatar__custom-content"
     *ngIf="customContent"
     (click)="onEditClick()">
  <ng-content></ng-content>

  <input type="file"
         *ngIf="loadfile"
         accept="image/x-png, image/gif, image/jpeg"
         class="avatar__edit-input custom"
         (change)="fileChangeListener($event)">
</div>
