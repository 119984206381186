import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ot-show-more',
  templateUrl: './show-more.html',
  styleUrls: ['show-more.scss']
})
export class ShowMoreComponent {
  @Input() public label: string = 'Show more';
  @Input() public initialText: string = '';
  @Input() public expanded: boolean = false;
  @Input() public bottomHide: boolean = true;
  @Input() public rightAlign: boolean = true;
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix
  @Output('onToggle') private onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('content') private content: ElementRef;

  public toggle() {
    this.expanded = !this.expanded;

    this.onToggle.emit(this.expanded);
  }

  public collapse() {
    const height = this.content.nativeElement.offsetHeight;

    this.expanded = false;

    this.onToggle.emit(false);

    window.scrollTo(0, window.scrollY - height);
  }
}
