import { Pipe, PipeTransform } from '@angular/core';

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

@Pipe({ name: 'otPlayerTimer' })
export class PlayerTimerPipe implements PipeTransform {
  public transform(givenSeconds: number): string {
    const timeInSeconds: number = Math.floor(givenSeconds);
    const seconds: number = this.getSeconds(timeInSeconds);
    const minutes: number = this.getMinutes(timeInSeconds);
    const hours: number = this.getHours(timeInSeconds);

    const formattedHours = hours ? `${this.getFormatedValue(hours)}:` : '';
    const formattedMinutes = `${this.getFormatedValue(minutes)}:`;
    const formattedSeconds = this.getFormatedValue(seconds);

    return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
  }

  private getSeconds(seconds) {
    return Math.floor(seconds) % SECONDS_IN_MINUTE;
  }

  private getMinutes(seconds) {
    return Math.floor((seconds / SECONDS_IN_MINUTE)) % MINUTES_IN_HOUR;
  }

  private getHours(seconds) {
    return Math.floor(seconds / SECONDS_IN_MINUTE / MINUTES_IN_HOUR);
  }

  private getFormatedValue(value) {
    return value < 10 ? `0${value}` : value;
  }
}
