import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AppService } from '../../../../app.service';

@Component({
  selector: 'ot-captcha',
  templateUrl: './captcha.html',
  styleUrls: ['captcha.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaptchaComponent {
  @ViewChild('captchaRef') captchaRef: ElementRef = null!;

  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-on-prefix
  @Output('onResponse')
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  private onResponse: EventEmitter<string> = new EventEmitter<string>();

  public key: string = this.appService.getSettings().captcha;

  constructor(private appService: AppService) {}
  
  public handleResponse(event: any) {
    this.onResponse.emit(event);
  }

  public execute() {
    (<any> this.captchaRef).execute();
  }
}
