<!-- eslint-disable @angular-eslint/template/elements-content -->
<div class="dialog-wrapper" *ngIf="opened" [class]="{opened: opened, 'mobile-flex': mobileFlex}">
  <div class="dialog" #dialog [ngStyle]="sizes">
    <div class="dialog__header" [class]="{ error: isError }">
      <div class="dialog__title">
        {{ title }}
      </div>
      <button class="dialog__close" [class]="{ error: isError }" (click)="close()" *ngIf="!hideCloseButton"></button>
    </div>
    <ng-content ></ng-content>
  </div>
</div>
